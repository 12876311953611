.month-picker-wrapper .react-datepicker__input-container input {
    height: 40px !important;
    width: 200px !important;
    border: 1px solid #E5E7EB !important;
}

@media screen and (min-width: 320px) and (max-width: 820px) { 
    .month-picker-wrapper .react-datepicker__input-container input {
        height: 40px !important;
        width: 100px !important;
        border: 1px solid #E5E7EB !important;
    }
}

/* @media screen and (min-width: 320px) and (max-width: 768px) {  */
    .month-picker-wrapper-mobile .react-datepicker__input-container input {
        height: 40px !important;
        width: 9rem !important;
        border: 1px solid #E5E7EB !important;
    }
/* } */


/* .date-picker-container {
    margin-right: 10px; 
} */