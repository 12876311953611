@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'candera-regular';
    src: url("../public/Candara/static/WorkSans-Regular.ttf") format('truetype');
}

body {
  margin: 0;
  font-family:'candera-regular' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropdown-max-height {
    height: auto;
    overflow-y: auto;
    max-height: 200px;
  }

