.sidebar-bg {
  background-color: #B7E4C7;
}

.label-bg {
  background-color: white;
}

.form-bg {
  background-color: white;
}

.main-bg {
  background-color: gray;

}

.grid-container {
  display: grid;
}

.org {
  width: 545px !important;
}

@media (max-width: 767px) {
  .org {
    width: 150% !important;
  }
}

/* Optional: Specific width for tablets if needed */
@media (min-width: 768px) and (max-width: 1024px) {
  .org {
    width: 500px !important;
  }
}

.custom>.date-picker-wrapper>.react-datepicker__input-container input {
  width: 100% !important;
}

.custom .date-picker-wrapper {
  width: 100% !important;
}

.react-datepicker__input-container input {
  height: 72px !important;
  border: 1px solid #ccc !important;
  border-radius: 15px !important;
  padding: 15px !important;


}

.react-datepicker__year .react-datepicker__year-text {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #333 !important;

}

.validate-ocr .react-datepicker__input-container input {
  height: 52px !important;
  width: 100%;


}

.validate-ocr .react-datepicker-wrapper {
  width: 100% !important;
}

.reccomadation .react-datepicker-wrapper{
  width: 100% !important;

}

.react-time-picker__wrapper input {
  height: 30px !important;
  /* border: 1px solid #ccc !important; */
  border-radius: 15px !important;
  padding: 10px !important;
  width: 20px !important;
  text-align: center !important;

}

.react-time-picker__wrapper {
  border-radius: 15px;
  background: white;
  /* padding: 5px !important; */
  padding-left: 5px !important;
  padding-right: 5px !important;

}

.add-holiday .react-datepicker__input-container input {
  width: 200px !important;
  height: 50px !important;
}

.maintainance .react-datepicker__input-container input {
  width: 200px !important;
  height: 50px !important;
}

.non-productive .react-datepicker__input-container input {
  width: 300px !important;
  height: 50px !important;
}

.state-list::-webkit-scrollbar {
  display: none;
}

.upload-bills::-webkit-scrollbar {
  width: 5px;
}

.aggregatedView::-webkit-scrollbar {
  width: 5px; /* Controls the width */
  height: 5px; /* Controls the height for horizontal scrollbars */
  
}

.aggregatedView::-webkit-scrollbar-thumb {
  background-color: grey; /* The scrollbar color */
  border-radius: 10px;
}

.aggregatedView::-webkit-scrollbar-track {
  background-color: white; /* The track color */
}
.upload-bills::-webkit-scrollbar-track {
  background: transparent;
  /* or specify a background color */
}

.upload-bills::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
}

.upload-bills::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* CSS to scale the checkbox */
.scale-checkbox {
  transform: scale(1.5);
  /* Adjust scale value to increase/decrease size */
  transform-origin: center;
}

.date-range-input {
  border: 1px solid #B9B9B9;
  /* Add border for unfocused state */
}

.date-range-input:focus {
  border: 1px solid #B9B9B9;
  /* Add border for focused state */
}

.yearly-tod-view .react-datepicker__input-container input {
  width: 100% !important;
  height: 40px !important;
}

.yearly-tod-view .react-datepicker {
  margin-top: 40px !important;
}

.yearly-tod-view .react-datepicker__triangle {
  display: none;
  /* Hide the dropdown icon */
}

.futureLoad .react-datepicker__input-container input {
  width: 100% !important;
  height: 40px !important;
}

.futureLoad .react-datepicker {
  margin-top: 40px !important;
}

.futureLoad .react-datepicker__triangle {
  display: none;
  /* Hide the dropdown icon */
}

.is-it-applicable .react-datepicker__input-container input {
  width: 180px !important;
  height: 40px !important;
  margin-right: 16px !important;
}

/* CSS */
.custom-select-container {
  font-size: 10px;
  border-radius: 20px !important;
  /* Adjust the font size as needed */
}

.custom-select-placeholder,
.custom-select-value {
  font-size: 10px;
  border-radius: 20px !important;
  /* Adjust the font size as needed */
}

/* CSS */
.date-range-picker .rdrMonthAndYearPickers select,
.date-range-picker .rdrDateDisplayWrapper input[type="text"] {
  font-size: 10px !important;
  /* Adjust the font size as needed */
}

.custom-multi-select .custom-select__input-container .css-qbdosj-Input input {
  border-radius: 50px !important;
}

.dashboard .custom-select__input {
  border-radius: 50px !important;
  height:11px !important;
  border: 1px solid rgb(223, 222, 222) !important;

}

mark {
  background: orange;
  color: black;
}

/* .react-pdf__Page__canvas{
  width: 100% !important;
  height: 100% !important;
  margin: auto !important;
} */
.pdf-view::-webkit-scrollbar {
  display: none;
}

.rdrDefinedRangesWrapper {
  width: 0px !important;

}

.line-graph {
  position: relative;
  margin: auto;
  width: 80vw;
  height: 80vh;
}

@media (max-width: 576px) {
  .line-graph {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-55%, -50%) rotate(-90deg);
    transform-origin: center center;
    width: 80vh;
    height: 80vw;
  }
}

.futureLoad .react-datepicker__year-text--disabled {
  color: gray !important;
}

/* css for PDF Viewer start */
.viewer {
  background-color: #e4e4e4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.rpv-search__highlight--current {
  border-radius: 0px !important;
  display: inline;
  border: 2px dashed #33b71e;
  background-color: #cae52285 !important;
}

.rpv-core__inner-page {
  top: 30px !important
}

.dashboard .react-datepicker__input-container input {
  width: 100% !important;
  height: 40px !important;
  border: 1px solid rgb(223, 222, 222) !important;
  border-radius: 10px !important;
}

.compare-ocr .react-datepicker__input-container input {
  width: 100% !important;
  height: 40px !important;  
  border: 1px solid gray !important;
  border-radius: 10px !important;
}

.powerconsumption .react-datepicker__input-container input {
    width: 100% !important;
    height: 40px !important;  

    border-radius: 10px !important;
  }

.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}

.customDatePickerWidth > .reactPowerConsumptionDatePicker{
    border:none !important;
}

.icon-line {
  display: flex;
  align-items: center;
}

.icon-line::before,
.icon-line::after {
  content: '\f111';
  /* Unicode for fa-circle icon */
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
  margin: 0 5px;
}

.icon-line .line {
  height: 2px;
  background-color: currentColor;
  flex-grow: 1;
}
.text-shadow {
    text-shadow: 2px 2px 5px gray;
  }
.number-container {
  display: inline-block;
  max-width: 100%; /* Ensure the container doesn't overflow */
  word-break: break-all; /* Break long words to wrap */
  text-align: center; /* Center align the text */
}

.dropdown-container{
  /* border-gray-200 */
  border: 1px solid rgb(228, 225, 225) !important;
  border-radius: 10px !important;
}
.dashboard-tooltip{
  z-index: 20;
}
.custom-multi-select .dropdown-content {
  z-index: 50 !important;
  position: relative;
}
.icon-container {
  position: relative;
  z-index: 0 !important;
}

/*  */
.fade-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
.fade-exit {
  opacity: 1;
  transform: translateX(0);
}
.fade-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 500ms, transform 500ms;
}
/* Hides the spinner in Chrome, Safari, Edge, Opera */
.mobile-input .no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hides the spinner in Firefox */
.mobile-input .no-spinner[type="number"] {
    -moz-appearance: textfield;
}

.dashboard-popup table {
  border-collapse: collapse;
  width: 100%;
}

.dashboard-popup th,
.dashboard-popup td {
  border: 1px solid #ccc;
  padding: 8px; 
  text-align: left; 
}

.dashboard-popup th {
  font-weight: bold; 
}


